import React from "react";
import { Container, Row, Col, Form, Button, Nav } from "react-bootstrap";
import "./login.css";
import {
  faFacebook,
  faApple,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import FacebookLoginButton from "./facebookloginBtn";

const LoginForm = () => {
  return (
    <Form
      className="d-flex flex-column align-items-center "
      style={{ width: "100%" }}
    >
      <h2 className="text-center">Login</h2>
      <p>
        dont have an account? <Link to="/signup">Sign Up</Link>{" "}
      </p>

      <Form.Group controlId="formBasicEmail" className="w-100 my-2 ">
        {/* <Form.Label>Email address</Form.Label> */}
        <Form.Control type="email" placeholder="Enter email" />
      </Form.Group>

      <Form.Group controlId="formBasicPassword" className="w-100 my-2">
        {/* <Form.Label>Password</Form.Label> */}
        <Form.Control type="password" placeholder="Password" />
      </Form.Group>

      <Form.Group
        controlId="formBasicCheckbox"
        className="w-100 d-flex justify-content-between my-4"
      >
        <Form.Check type="checkbox" label="Remember me" />
        <Form.Text className="text-muted">
          <a href="#forget-password">Forgot Password?</a>
        </Form.Text>
      </Form.Group>

      <Form.Group className="w-100"></Form.Group>

      <Button variant="primary" type="submit" className="submitbtn w-100">
        login
      </Button>
      <div className="mt-3 text-center">
        or continue with
        <ul className="ullogin d-flex justify-content-center">
          <li className="m-0">
            <FacebookLoginButton/>
            {/* <FontAwesomeIcon icon={faFacebook} size="2x" style={{color:"blue"}} /> */}
          </li>
          {/* <li>
            {" "}
            <FontAwesomeIcon icon={faApple} size="1x" />
          </li>
          <li>
            {" "}
            <FontAwesomeIcon icon={faGoogle} size="1x" />
          </li> */}
        </ul>
      </div>
    </Form>
  );
};

const Login = () => {
  return (
    <>
      <Nav as="ul" className="" style={{ backgroundColor: "#0F052A" }}>
        <Nav.Item as="li">
          <FontAwesomeIcon icon="home" style={{ color: "blue" }} />

          <Link to="/" className="nav-link">
            {" "}
            Home
          </Link>
        </Nav.Item>
      </Nav>
      <Container className=" d-flex align-items-center justify-content-center mt-5 ">
        <Row className="">
          <Col
            sm={12}
            md={6}
            className="rightcol d-flex justify-content-center align-items-center"
            // style={{ border: "2px solid red" }}
          >
            {/* <div className="imgdiv ">
              <img
                src="bluelogo.png" // Replace with the path to your logo
                alt="Logo"
                style={{ width: "150px", marginBottom: "20px" }}
                className=""
              />
            </div> */}
            <LoginForm />
          </Col>
          <Col
            md={6}
            className="p-5 d-flex justify-content-center align-items-center "
            // style={{ border: "2px solid green" }}
          >
            <img
              src="login2.jpg" // Replace with the path to your image
              alt="Responsive"
              className="img-fluid"
              style={{ borderRadius: "20px" }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
