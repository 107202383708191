import React, { useState, useRef, useEffect } from 'react';
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import { FaPhoneAlt, FaPlug, FaExpandAlt, FaPlay, FaPause } from 'react-icons/fa';
import './TalkWithAi.css';

const TalkWithAi = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioProgress, setAudioProgress] = useState(0);
  const audioRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const audio = audioRef.current;
    const updateProgress = () => {
      const progress = (audio.currentTime / audio.duration) * 100;
      setAudioProgress(progress);
    };

    audio.addEventListener('timeupdate', updateProgress);
    return () => audio.removeEventListener('timeupdate', updateProgress);
  }, []);

  const LeftSideContent = () => (
    <div className="left-side-content">
      <div className="talk-with-ai-item">
        <FaPhoneAlt className="talk-with-ai-icon" />
        <div>
          <h3>Engage with faster, better phone calls</h3>
          <p>Your agent delivers delightful and personalized conversations. Always available, endlessly patient, and able to reason, predict, and act in real-time.</p>
        </div>
      </div>
      <div className="talk-with-ai-item">
        <FaPlug className="talk-with-ai-icon" />
        <div>
          <h3>Connect to your call center ecosystem</h3>
          <p>Seamlessly integrate with your existing technology stack, with comprehensive summaries and intelligent routing when escalation is required.</p>
        </div>
      </div>
      <div className="talk-with-ai-item">
        <FaExpandAlt className="talk-with-ai-icon" />
        <div>
          <h3>Scale consistent experiences on every channel</h3>
          <p>Provide a unified experience across various communication channels, ensuring consistency and quality in every interaction.</p>
        </div>
      </div>
    </div>
  );

  return (
    <section className="talk-with-ai-section">
      <div className="talk-with-ai-header">
        <h2 className="talk-with-ai-heading">Let Ai Talk</h2>
        <p className="talk-with-ai-subheading">
          Elevate your customer service with AI-powered conversations that are fast, efficient, and personalized.
        </p>
      </div>
      <Container>
        <div className="talk-with-ai-content">
          <Button variant="primary" className="cta-button">Try AI Talker Now</Button>
          
          <Row>
            <Col md={6}>
              <LeftSideContent />
            </Col>
            <Col md={6}>
              <Card className="conversation-card">
                <Card.Body>
                  <div className="wave-animation">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                  </div>
                  <div className="audio-player-container">
                    <Button onClick={togglePlay} className="play-button">
                      {isPlaying ? <FaPause /> : <FaPlay />}
                    </Button>
                    <audio ref={audioRef} src="/21230.wav" onEnded={() => setIsPlaying(false)} />
                  </div>
                  <div className="audio-progress-bar" style={{ width: `${audioProgress}%` }}></div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default TalkWithAi;
