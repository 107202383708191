import React from 'react';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';

const Blog = () => {
  return (
    <div className="blog-container">
      <LoadingAnimation />
    </div>
  );
};

export default Blog;
