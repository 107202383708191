import React, { useState, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import './AiTalkerAnimation.css';

const AiTalkerAnimation = () => {
  const [step, setStep] = useState(0);
  const [typedName, setTypedName] = useState('');
  const [currentVoiceIndex, setCurrentVoiceIndex] = useState(0);

  const steps = [
    { text: "Personalize your Ai Talker", animation: "wave", duration: 2000 },
    { text: "Give Your Ai Talker a Name", animation: "name", duration: 2000 },
    { text: "Choose its voice", animation: "voice", duration: 3333 },
    { text: "Your Ai Talker is Ready!", animation: "ready", duration: 2000 }
  ];

  const voices = [
    "Aarav (Indian)", "Emma (British)", "Juan (Spanish)", "Yuki (Japanese)",
    "Zoe (French)", "Chen (Chinese)", "Aisha (Arabic)", "Igor (Russian)",
    "Sofia (Italian)", "Liam (Irish)", "Mei (Korean)", "Hans (German)",
    "Fatima (Egyptian)", "Raj (Indian)", "Olivia (American)", "Carlos (Mexican)",
    "Yara (Lebanese)", "Sven (Swedish)"
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      if (step < steps.length - 1) {
        setStep(step + 1);
      } else {
        // Add a small delay before restarting the animation
        setTimeout(() => setStep(0), 1000);
      }
    }, steps[step].duration);

    return () => clearTimeout(timer);
  }, [step]);

  useEffect(() => {
    if (steps[step].animation === "name") {
      setTypedName('');
      const name = "Amanda";
      let typedString = '';
      let i = 0;
      const typingInterval = setInterval(() => {
        if (i < name.length) {
          typedString += name[i];
          setTypedName(typedString);
          i++;
        } else {
          clearInterval(typingInterval);
        }
      }, 100);

      return () => clearInterval(typingInterval);
    }

    if (steps[step].animation === "voice") {
      const voiceInterval = setInterval(() => {
        setCurrentVoiceIndex((prevIndex) => (prevIndex + 1) % voices.length);
      }, 200);

      return () => clearInterval(voiceInterval);
    }
  }, [step]);

  const generateConfetti = () => {
    const confetti = [];
    for (let i = 0; i < 50; i++) {
      const left = Math.random() * 100;
      const animationDuration = Math.random() * 2 + 1.3;
      confetti.push(
        <div
          key={i}
          className="confetti-particle"
          style={{
            left: `${left}%`,
            animationDuration: `${animationDuration}s`,
            animationDelay: `${Math.random() * 1.3}s`
          }}
        >
          🎉
        </div>
      );
    }
    return confetti;
  };

  const renderStepContent = () => {
    const isLastStep = step === steps.length - 1;
    const avatarClass = isLastStep ? "ai-avatar" : "ai-icon";

    switch (steps[step].animation) {
      case "wave":
        return (
          <div className="waving-container">
            <div className={`${avatarClass} waving`}>👋</div>
          </div>
        );
      case "name":
        return (
          <div className="typing-container">
            <h2 className="typed-name">{typedName}</h2>
          </div>
        );
      case "voice":
        return (
          <div className="voice-container">
            {voices.map((voice, index) => {
              const offset = index - currentVoiceIndex;
              const isVisible = Math.abs(offset) <= 2;
              return (
                <div
                  key={voice}
                  className={`voice-option ${index === currentVoiceIndex ? 'active' : ''} ${isVisible ? 'visible' : ''}`}
                  style={{
                    transform: `translateY(${offset * 40}px)`,
                    opacity: isVisible ? 1 - Math.abs(offset) * 0.3 : 0,
                  }}
                >
                  {voice}
                </div>
              );
            })}
          </div>
        );
      case "ready":
        return (
          <div className="ready-container">
            <div className="big-confetti">🎉</div>
            {generateConfetti()}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="ai-talker-animation">
      <h3>{steps[step].text}</h3>
      <div className="content-container">
        {renderStepContent()}
      </div>
      <div className="bottom-container">
        <ProgressBar now={(step / (steps.length - 1)) * 100} className="mb-3" />
      </div>
    </div>
  );
};

export default AiTalkerAnimation;
