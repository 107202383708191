import React from 'react';
import './termsOfService.css';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <div className="terms-header">
        <h1>Verbalyze Terms of Service</h1>
        <p>Last updated: February 12, 2024</p>
      </div>
      
      <div className="terms-content">
        <div className="terms-intro">
          <p>Please read these Terms of Service (the "Terms") and our <a href="https://verbalyze.in/privacy-policy">Privacy Policy</a> carefully because they govern your use of Aiverbalyze Technologies Pvt. Ltd.'s ("Verbalyze", "we" or "our") website located at <a href="https://verbalyze.in">https://verbalyze.in</a> (the "Site"). To make these Terms easier to read, the Site and any services offered via our Site are collectively called the "Services."</p>
        </div>

        <article className="terms-section">
          <h2>1. Agreement to Terms</h2>
          <p>By using our Services, you agree to be bound by these Terms. If you don't agree to be bound by these Terms, do not use the Services. If you are accessing and using the Services on behalf of a company (such as your employer) or other legal entity, you represent and warrant that you have the authority to bind that entity to these Terms. In that case, "you" and "your" will refer to that entity.</p>
        </article>

        <article className="terms-section">
          <h2>2. Privacy Policy</h2>
          <p>Please review our <a href="https://verbalyze.in/privacy-policy">Privacy Policy</a>, which also governs your use of the Services, for information on how we collect, use, and share your information.</p>
        </article>

        <article className="terms-section">
          <h2>3. Changes to these Terms or the Services</h2>
          <p>We may update the Terms from time to time at our sole discretion. If we do, we'll notify you by posting the updated Terms on the Site, and/or may also send other communications. It's important that you review the Terms whenever we update them or you use the Services. If you continue to use the Services after we have posted updated Terms, it means that you accept and agree to the changes. If you don't agree to be bound by the changes, you may not use the Services anymore. Because our Services are evolving over time, we may change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.</p>
        </article>

        <article className="terms-section">
          <h2>4. Feedback</h2>
          <p>We appreciate feedback, comments, ideas, proposals, and suggestions for improvements to the Services ("Feedback"). If you choose to submit Feedback, you agree that we are free to use it without any restriction or compensation to you.</p>
        </article>

        <article className="terms-section">
          <h2>5. General Prohibitions and Verbalyze's Enforcement Rights</h2>
          <p>You agree not to do any of the following:</p>
          <ul>
            <li>Use, display, mirror, or frame the Services or any individual element within the Services, Verbalyze's name, any Verbalyze trademark, logo, or other proprietary information, or the layout and design of any page or form contained on a page, without Verbalyze's express written consent;</li>
            <li>Access, tamper with, or use non-public areas of the Services, Verbalyze's computer systems, or the technical delivery systems of Verbalyze's providers;</li>
            <li>Attempt to probe, scan, or test the vulnerability of any Verbalyze system or network or breach any security or authentication measures;</li>
            <li>Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Verbalyze or any of Verbalyze's providers or any other third party (including another user) to protect the Services;</li>
            <li>Attempt to access or search the Services or download content from the Services using any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by Verbalyze or other generally available third-party web browsers;</li>
            <li>Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters, or other form of solicitation;</li>
            <li>Use any meta tags or other hidden text or metadata utilizing a Verbalyze trademark, logo URL, or product name without Verbalyze's express written consent;</li>
            <li>Use the Services, or any portion thereof, for any commercial purpose or for the benefit of any third party or in any manner not permitted by these Terms;</li>
            <li>Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Services to send altered, deceptive, or false source-identifying information;</li>
            <li>Attempt to decipher, decompile, disassemble, or reverse engineer any of the software used to provide the Services;</li>
            <li>Interfere with, or attempt to interfere with, the access of any user, host, or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;</li>
            <li>Collect or store any personally identifiable information from the Services from other users of the Services without their express permission;</li>
            <li>Impersonate or misrepresent your affiliation with any person or entity;</li>
            <li>Violate any applicable law or regulation; or</li>
            <li>Encourage or enable any other individual to do any of the foregoing.</li>
          </ul>
          <p>Verbalyze is not obligated to monitor access to or use of the Services or to review or edit any content. However, we have the right to do so for the purpose of operating the Services, to ensure compliance with these Terms, and to comply with applicable law or other legal requirements. We reserve the right, but are not obligated, to remove or disable access to any content, at any time and without notice, including, but not limited to, if we, at our sole discretion, consider it objectionable or in violation of these Terms. We have the right to investigate violations of these Terms or conduct that affects the Services. We may also consult and cooperate with law enforcement authorities to prosecute users who violate the law.</p>
        </article>

        <article className="terms-section">
          <h2>6. Links to Third Party Websites or Resources</h2>
          <p>The Services may allow you to access third-party websites or other resources. We provide access only as a convenience and are not responsible for the content, products, or services on or available from those resources or links displayed on such websites. You acknowledge sole responsibility for and assume all risk arising from your use of any third-party resources.</p>
        </article>

        <article className="terms-section">
          <h2>7. Termination</h2>
          <p>We may suspend or terminate your access to and use of the Services, including suspending access to or terminating your account, at our sole discretion, at any time and without notice to you. You may cancel your account at any time by contacting us at <a href="mailto:verbalyzeteam@verbalyze.in">verbalyzeteam@verbalyze.in</a>. Upon any termination, discontinuation, or cancellation of the Services or your account, the following Sections will survive: 5, 6, 7, 8, 9, 10, 11, and 12.</p>
        </article>

        <article className="terms-section">
          <h2>8. Warranty Disclaimers</h2>
          <p>THE SERVICES ARE PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. We make no warranty that the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness, or reliability of any information or content on the Services.</p>
        </article>

        <article className="terms-section">
          <h2>9. Indemnity</h2>
          <p>You will indemnify and hold Verbalyze and its officers, directors, employees, and agents harmless from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way connected with (a) your access to or use of the Services, or (b) your violation of these Terms.</p>
        </article>

        <article className="terms-section">
          <h2>10. Limitation of Liability</h2>
          <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER VERBALYZE NOR ITS SERVICE PROVIDERS INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT VERBALYZE OR ITS SERVICE PROVIDERS HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</p>
          <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL VERBALYZE'S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE PAID OR ARE PAYABLE BY YOU TO VERBALYZE FOR USE OF THE SERVICES OR ONE HUNDRED DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO VERBALYZE, AS APPLICABLE.</p>
          <p>THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN VERBALYZE AND YOU.</p>
        </article>

        <article className="terms-section">
          <h2>11. Governing Law and Forum Choice</h2>
          <p>These Terms and any action related thereto will be governed by the laws of India, without regard to its conflict of laws provisions. The exclusive jurisdiction and venue of any action with respect to the subject matter of these Terms will be the courts located in Thane, Maharashtra, India, and each of the parties hereto waives any objection to jurisdiction and venue in such courts.</p>
        </article>

        <article className="terms-section">
          <h2>12. General Terms</h2>
          <p><strong>Reservation of Rights.</strong> Verbalyze and its licensors exclusively own all right, title, and interest in and to the Services, including all associated intellectual property rights. You acknowledge that the Services are protected by copyright, trademark, and other laws of India and foreign countries. You agree not to remove, alter, or obscure any copyright, trademark, service mark, or other proprietary rights notices incorporated in or accompanying the Services.</p>
          <p><strong>Entire Agreement.</strong> These Terms constitute the entire and exclusive understanding and agreement between Verbalyze and you regarding the Services, and these Terms supersede and replace all prior oral or written understandings or agreements between Verbalyze and you regarding the Services. If any provision of these Terms is held invalid or unenforceable by a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect. You may not assign or transfer these Terms, by operation of law or otherwise, without Verbalyze's prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null. Verbalyze may freely assign or transfer these Terms without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors, and permitted assigns.</p>
          <p><strong>Notices.</strong> Any notices or other communications provided by Verbalyze under these Terms will be given: (i) via email; or (ii) by posting to the Services. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted.</p>
          <p><strong>Waiver of Rights.</strong> Verbalyze's failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Verbalyze. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.</p>
        </article>

        <article className="terms-section">
          <h2>13. Contact Information</h2>
          <p>If you have any questions about these Terms or the Services, please contact Verbalyze at <a href="mailto:verbalyzeteam@verbalyze.in">verbalyzeteam@verbalyze.in</a> or Aiverbalyze Technologies Pvt. Ltd., Thane, Maharashtra, India.</p>
        </article>
      </div>

      <div className="cta-section">
        <h2>See what Verbalyze can do for you.</h2>
        <p>Find out how Verbalyze can help your company transform the customer experience with our conversational AI agents.</p>
        <Link to="/contact" className="cta-button">Learn more</Link>
      </div>
    </div>
  );
};

export default TermsOfService;
