import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Header.css"
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { logoBlue } from "../../assets";

function Header() {
   const scrollToComponent = () => {
     const component = document.getElementById("meet-calender");
     if (component) {
       component.scrollIntoView({ behavior: "smooth" });
     }
   };

  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="navbar__bg navbar-light p-2"
        >
          <Container>
            <Link to="/">
              <img
                src={logoBlue}
                alt=""
                style={{ width: "220px", height: "60px", margin: "10px" }}
              />
            </Link>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="mobile-menu"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img
                    src={logoBlue}
                    alt=""
                    style={{ width: "200px", height: "50px", margin: "10px" }}
                  />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                  <Link to="/pricing" className="nav-link">
                    Pricing
                  </Link>
                  <Link to="/meet" className="nav-link" onClick={scrollToComponent}>
                    Meet us
                  </Link>
                  <Link
                    to="https://admin.verbalyze.in/"
                    className="nav-link login-button"
                  >
                    Login
                  </Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default Header;
