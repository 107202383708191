import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaPhone, FaDesktop, FaMobileAlt, FaClock, FaHeadset, FaHourglassHalf, FaCheckCircle, FaUserCog } from 'react-icons/fa';
import './AiTalker.css';
import { phoncl } from '../../assets';

const AiTalker = () => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const phrases = [
    "available 24X7",
    "understands multiple languages",
    "handles customer queries",
    "scales with your business"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 3000); // Change phrase every 3 seconds

    return () => clearInterval(interval);
  }, [phrases.length]);

  return (
    <section className="ai-talker-section" id="aiTalkerSection">
      <Container>
        <h2 className="text-center mb-4">Never Miss a Customer Call with <span className="ai-talker-highlight">AI-Talker</span></h2>
        <p className="text-center mb-5 subtitle">
          AI call handling agent that
          <span className="animated-text-container">
            {phrases.map((phrase, index) => (
              <span
                key={index}
                className={`animated-text ${index === currentPhraseIndex ? 'active' : ''}`}
              >
                {phrase}
              </span>
            ))}
          </span>
        </p>
        <Row className="justify-content-center">
          <Col lg={12} className="mb-4">
            <Card className="h-100 ai-talker-card phone-call">
              <Card.Body>
                <Row>
                  <Col md={4} className="d-flex align-items-center justify-content-center mb-4 mb-md-0">
                    <img src={phoncl} alt="Phone Call" className="phone-call-image" />
                  </Col>
                  <Col md={8}>
                    <div className="content-wrapper">
                      <div className="d-flex align-items-center mb-3">
                        <FaPhone className="feature-icon mr-3" />
                        <h3 className="card-title mb-0">Phone Call</h3>
                      </div>
                      <p className="mb-4">
                      Let AI handle all your calls through advanced AI technology. Customers enjoy shorter wait times, 24X7 instant responses, and every interaction is logged and analyzed automatically, ensuring a personalized and efficient service experience.
                      </p>
                      <div className="feature-list-container">
                        <ul className="feature-list">
                          <li className="feature-list-item">
                            <FaClock className="feature-icon-small" />
                            <span>24/7 customer support</span>
                          </li>
                          <li className="feature-list-item">
                            <FaHeadset className="feature-icon-small" />
                            <span>Efficient call handling</span>
                          </li>
                          <li className="feature-list-item">
                            <FaHourglassHalf className="feature-icon-small" />
                            <span>Reduced wait times</span>
                          </li>
                          <li className="feature-list-item">
                            <FaCheckCircle className="feature-icon-small" />
                            <span>Consistent service quality</span>
                          </li>
                          <li className="feature-list-item">
                            <FaUserCog className="feature-icon-small" />
                            <span>Seamless human escalation</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} className="mb-4">
            <Card className="h-100 ai-talker-card">
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <FaDesktop className="feature-icon mr-3" />
                  <h3 className="card-title mb-0">Web Application</h3>
                </div>
                <p>
                  Versatile customer support through websites. 
                  Ideal for businesses seeking omnichannel consistency.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} className="mb-4">
            <Card className="h-100 ai-talker-card">
              <Card.Body>
                <div className="d-flex align-items-center mb-3">
                  <FaMobileAlt className="feature-icon mr-3" />
                  <h3 className="card-title mb-0">Mobile Application</h3>
                </div>
                <p>
                  On-the-go customer interaction. 
                  Perfect for E-Commerce, Online Delivery service for responsive service.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AiTalker;
