import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <p>Schedule a meeting with us using the calendar below:</p>
      {/* Google Calendar Appointment Scheduling begin */}
      <iframe 
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2y-VtvsmvfxsJh8iEMqsML7jplPFVdFH3ZYecJszGV_0p1KWQuar18QFnMJqsVuSEuR_09KuDy?gv=true" 
        style={{ border: 0 }} 
        width="100%" 
        height="600" 
        frameBorder="0"
        title="Google Calendar Appointment Scheduling"
      ></iframe>
      {/* end Google Calendar Appointment Scheduling */}
    </div>
  );
};

export default ContactUs;
