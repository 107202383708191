import React, { useEffect } from 'react'
import Banner from '../../components/Banner/Banner';
import { Helmet } from 'react-helmet-async';

import "./home.css"
import SupportPartner from '../../components/Supportpartner/Supportpartner';
import Glossary from '../../components/Glossary/Glossary';
import ContactUs from '../../components/ContactUs/ContactUs';
import AiTalker from '../../components/AiTalker/AiTalker';
import CustomerExperience from '../../components/FeatureSection/CustomerExperience';
import TalkWithAi from '../../components/FeatureSection/TalkWithAi';
import YourOwnAi from '../../components/FeatureSection/YourOwnAi';
import SafeAndSecure from '../../components/FeatureSection/SafeAndSecure';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="homebg">
      <Helmet>
        <title>Verbalyze | Home</title>
        <meta
          name="description"
          content="Elevate interactions with Verbalyze: AI for automated calls, emails, chat, and WhatsApp. Transform telemarketing, support, and sales with seamless, personalized onboarding across channels. Say goodbye to traditional methods, embrace intelligent automation. Verbalyze: Redefining customer engagement."
        />
      </Helmet>
      <Banner />
      <AiTalker />
      <CustomerExperience />
      <TalkWithAi />
      <YourOwnAi />
      <SafeAndSecure />
      <SupportPartner />
      <Glossary />
      <ContactUs />
    </div>
  );
}

export default Home
