import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaBox, FaEnvelope, FaBook } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './ContactUs.css';

const ContactUs = () => {
  const scrollToAiTalker = () => {
    const aiTalkerSection = document.getElementById('aiTalkerSection');
    if (aiTalkerSection) {
      aiTalkerSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="contact-us-section py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={10} lg={8} className="text-center">
            <h2 className="section-title mb-4">Manage Your Customers 24x7</h2>
            <p className="section-description mb-5">
              Discover how our AI-powered solutions can revolutionize your customer interactions and boost your business growth.
            </p>
            <div className="button-group">
              <Button variant="outline-primary" className="action-btn" size="lg" onClick={scrollToAiTalker}>
                <FaBox className="btn-icon" /> Our Products
              </Button>
              <Link to="/meet-us">
                <Button variant="primary" className="action-btn" size="lg">
                  <FaEnvelope className="btn-icon" /> Get in Touch
                </Button>
              </Link>
              <Link to="/blog">
                <Button variant="outline-primary" className="action-btn" size="lg">
                  <FaBook className="btn-icon" /> Read Our Blog
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
