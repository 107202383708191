import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./signup.css";
import {
  faFacebook,
  faApple,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";




const SignupForm = () => {
  return (
    <Form
      className="d-flex flex-column align-items-center "
      style={{ width: "100%" }}
    >
      
      <h2 className="text-center">Sign Up</h2>
      <p>
        already have an account? <Link to="/login">Login</Link>{" "}
      </p>

      <Form.Group controlId="formBasicEmail" className="w-100 my-2 mt-4">
        {/* <Form.Label>Name</Form.Label> */}
        <Form.Control type="text" placeholder="Enter Name" />
      </Form.Group>

      <Form.Group controlId="formBasicPassword" className="w-100 my-2">
        {/* <Form.Label>Email</Form.Label> */}
        <Form.Control type="password" placeholder="Enter Email" />
      </Form.Group>
      <Form.Group controlId="formBasicPassword" className="w-100 my-2">
        {/* <Form.Label>Password</Form.Label> */}
        <Form.Control type="password" placeholder="Password" />
      </Form.Group>
      <Form.Group controlId="formBasicPassword" className="w-100 my-2">
        {/* <Form.Label>Confirm Password</Form.Label> */}
        <Form.Control type="password" placeholder="Confirm Password" />
      </Form.Group>
      <Form.Group className="w-100"></Form.Group>

      <Button variant="primary" type="submit" className="submitbtn w-100 mt-2">
        Sign Up
      </Button>
      {/* <div className="mt-3 ">
        or continue with
        <ul className="ullogin d-flex justify-content-between">
          <li>
            <FontAwesomeIcon icon={faFacebook} size="1x" />
          </li>
          <li>
            {" "}
            <FontAwesomeIcon icon={faApple} size="1x" />
          </li>
          <li>
            {" "}
            <FontAwesomeIcon icon={faGoogle} size="1x" />
          </li>
        </ul>
      </div> */}
    </Form>
  );
};

const Signup = () => {
  return (
    <>
      <Nav as="ul" className="" style={{ backgroundColor: "#0F052A" }}>
        <Nav.Item as="li">
          <FontAwesomeIcon icon="home" style={{ color: "blue" }} />

          <Link to="/" className="nav-link">
            {" "}
            Home
          </Link>
        </Nav.Item>
      </Nav>
      <Container className="d-flex align-items-center justify-content-center mt-2">
        <Row className="">
          <Col
            sm={12}
            md={6}
            className="rightcol d-flex justify-content-center align-items-center"
            // style={{ border: "2px solid red" }}
          >
            {/* <div className="imgdiv ">
              <img
                src="bluelogo.png" // Replace with the path to your logo
                alt="Logo"
                style={{ width: "150px", marginBottom: "20px" }}
                className=""
              />
            </div> */}
            <SignupForm />
          </Col>
          <Col
            md={6}
            className="p-5 d-flex justify-content-center align-items-center "
            // style={{ border: "2px solid green" }}
          >
            <img
              src="signup.jpg" // Replace with the path to your image
              alt="Responsive"
              className="img-fluid"
              style={{ borderRadius: "20px" }}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Signup;
