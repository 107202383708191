import React from 'react';
import { Link } from 'react-router-dom';
import '../Industries/Industries.css';
import { FaTachometerAlt, FaBolt, FaFileInvoiceDollar, FaTools } from 'react-icons/fa';

const Utilities = () => {
  return (
    <div className="industry-container">
      <section className="industry-header">
        <h1>AI-Powered Solutions for Utilities</h1>
        <p>Optimize customer service and boost operational efficiency in the utilities sector with advanced AI technology.</p>
      </section>
      
      <section className="features">
        <h2>Key Benefits for Utilities</h2>
        <ul>
          <li>
            <FaTachometerAlt className="icon" />
            <div>
              <h3>Smart Meter Reading Management</h3>
              <p>Simplify meter reading reports and provide instant confirmations to customers.</p>
            </div>
          </li>
          <li>
            <FaBolt className="icon" />
            <div>
              <h3>Efficient Outage Reporting</h3>
              <p>Streamline power outage reports and deliver real-time status updates to customers.</p>
            </div>
          </li>
          <li>
            <FaFileInvoiceDollar className="icon" />
            <div>
              <h3>Automated Billing Assistance</h3>
              <p>Offer detailed bill explanations and guide customers through various payment options.</p>
            </div>
          </li>
          <li>
            <FaTools className="icon" />
            <div>
              <h3>Optimized Service Scheduling</h3>
              <p>Enhance the process of scheduling installations, repairs, and maintenance visits.</p>
            </div>
          </li>
        </ul>
      </section>
      
      <section className="cta">
        <h2>Ready to Revolutionize Your Utility Services?</h2>
        <p>Learn how Verbalyze can transform your customer interactions and operational efficiency. Contact us today for a tailored demonstration.</p>
        <Link to="/meet" className="cta-button">Request a Demo</Link>
      </section>
    </div>
  );
};

export default Utilities;
