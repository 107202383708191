import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./footer.css";
import { Link } from "react-router-dom";
import { FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footerbox text-light pt-2">
      <Container fluid className="py-4">
        <Row className="text-md-left py-4">
          {/* First Column - Logo and Social Links */}
          <Col md={3} className="mb-3 mb-md-0 pr-md-2 logo-column">
            <div className="align-items-center justify-content-md-start mb-4">
              <img src="whitelogo.png" alt="Logo" className="img-fluid mr-3 footer-logo" style={{width:"240px",height:"48px"}} />
              <div className="social-links d-flex pt-3">
                <Link to="https://www.instagram.com/" className="social-icon">
                  <FaInstagram />
                </Link>
                <Link to="https://www.linkedin.com/" className="social-icon">
                  <FaLinkedin />
                </Link>
                <Link to="https://twitter.com/" className="social-icon">
                  <FaTwitter />
                </Link>
                <Link to="https://www.youtube.com/" className="social-icon">
                  <FaYoutube />
                </Link>
              </div>
            </div>
          </Col>

          {/* Second Column - Company Links */}
          <Col md={3} className="mb-3 mb-md-0 px-md-2 footer-links-column">
            <ul className="list-unstyled footer-links">
              <li><Link className="footerli" to="/about-us">About us</Link></li>
              <li><Link className="footerli" to="/partner">Partner With Us</Link></li>
              <li><Link className="footerli" to="/careers">Career</Link></li>
              <li><Link className="footerli" to="/newsroom">Newsroom</Link></li>
              <li><Link className="footerli" to="/pricing">Pricing</Link></li>
              <li><Link className="footerli" to="/blog">Blog</Link></li>
              <li><Link className="footerli" to="/contact-us">Contact Us</Link></li>
            </ul>
          </Col>

          {/* Third Column - Industries */}
          <Col md={3} className="mb-3 mb-md-0 px-md-2 footer-links-column">
            <ul className="list-unstyled footer-links">
              <li><Link className="footerli" to="/industry/bfsi">BFSI</Link></li>
              <li><Link className="footerli" to="/industry/healthcare">Healthcare</Link></li>
              <li><Link className="footerli" to="/industry/utilities">Utilities</Link></li>
              <li><Link className="footerli" to="/industry/retail">Retail</Link></li>
              <li><Link className="footerli" to="/industry/other">Other Industries</Link></li>
            </ul>
          </Col>

          {/* Fourth Column - Legal */}
          <Col md={3} className="mb-3 mb-md-0 pl-md-2 footer-links-column">
            <ul className="list-unstyled footer-links">
              <li><Link className="footerli" to="/terms-of-service">Terms of Service</Link></li>
              <li><Link className="footerli" to="/data-security-policy">Data Security Policy</Link></li>
              <li><Link className="footerli" to="/privacy-policy">Privacy Policy</Link></li>
              <li><a className="footerli" href="/sitemap.xml">Sitemap</a></li>
              <li><Link className="footerli" to="/cookie-preferences">Cookie Preferences</Link></li>
            </ul>
          </Col>
        </Row>
      </Container>
      
      {/* Subfooter for Copyright */}
      <div className="text-center subfooter py-3">
        <Container>
          <p className="m-0">&copy; 2024 Verbalyze. All rights reserved.</p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
