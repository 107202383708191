import React from 'react';
import { Link } from 'react-router-dom';
import '../Industries/Industries.css';
import { FaCogs, FaGlobe, FaHandshake, FaChartBar } from 'react-icons/fa';

const OtherIndustries = () => {
  return (
    <div className="industry-container">
      <section className="industry-header">
        <h1>AI-Powered Solutions for All Industries</h1>
        <p>Enhance customer experience and boost operational efficiency across various sectors with our versatile AI technology.</p>
      </section>
      
      <section className="features">
        <h2>Key Benefits for Any Industry</h2>
        <ul>
          <li>
            <FaCogs className="icon" />
            <div>
              <h3>Customizable AI Responses</h3>
              <p>Tailor our AI solutions to match your industry-specific terminology and customer needs.</p>
            </div>
          </li>
          <li>
            <FaGlobe className="icon" />
            <div>
              <h3>Multi-lingual Support</h3>
              <p>Communicate with customers in multiple languages, expanding your global reach.</p>
            </div>
          </li>
          <li>
            <FaHandshake className="icon" />
            <div>
              <h3>Seamless Integration</h3>
              <p>Connect our AI solutions with your existing systems for enhanced efficiency.</p>
            </div>
          </li>
          <li>
            <FaChartBar className="icon" />
            <div>
              <h3>Scalable Solutions</h3>
              <p>Easily scale your customer service capabilities as your business grows.</p>
            </div>
          </li>
        </ul>
      </section>
      
      <section className="industries-list">
        <h2>Industries We Serve</h2>
        <ul>
          <li>Hospitality and Tourism</li>
          <li>Telecommunications</li>
          <li>E-commerce</li>
          <li>Education</li>
          <li>Real Estate</li>
          <li>And many more...</li>
        </ul>
      </section>
      
      <section className="cta">
        <h2>Ready to Revolutionize Your Industry?</h2>
        <p>Discover how Verbalyze can be tailored to meet your industry's unique needs. Contact us today for a customized demonstration.</p>
        <Link to="/meet" className="cta-button">Request a Demo</Link>
      </section>
    </div>
  );
};

export default OtherIndustries;
