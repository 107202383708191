import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import "./Pricing.css"

const PricingTier = ({ title, price, features }) => (
  <Card className="h-100 shadow-sm">
    <Card.Header className="text-center">
      <h3>{title}</h3>
    </Card.Header>
    <Card.Body>
      <Card.Title className="text-center">
        <h2>${price}</h2>
        <span className="period">/month</span>
      </Card.Title>
      <ul className="list-unstyled mt-3 mb-4">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <Button variant="primary" block>Choose Plan</Button>
    </Card.Body>
  </Card>
);

const Pricing = () => {
  const pricingData = [
    {
      title: "Standard",
      price: 99,
      features: [
        "Basic AI integration",
        "Up to 1000 interactions/month",
        "Email support",
        "Basic analytics"
      ]
    },
    {
      title: "Premium",
      price: 199,
      features: [
        "Advanced AI integration",
        "Up to 5000 interactions/month",
        "Priority email and phone support",
        "Advanced analytics",
        "Custom integrations"
      ]
    },
    {
      title: "Enterprise",
      price: "Custom",
      features: [
        "Full AI suite",
        "Unlimited interactions",
        "24/7 dedicated support",
        "Full analytics suite",
        "Custom development",
        "On-premise deployment option"
      ]
    }
  ];

  return (
    <div className="mainpricing py-5">
      <Container>
        <h1 className="text-center mb-5">Choose Your Plan</h1>
        <Row>
          {pricingData.map((tier, index) => (
            <Col key={index} md={4} className="mb-4">
              <PricingTier {...tier} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Pricing
