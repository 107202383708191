import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./service.css";

const OurServices = () => {
  const maleLanguages = [
    "Arabic",
    "Bengali",
    "English",
    "French",
    "Gujarati",
    "Hindi",
    "Marathi",
    "Russian",
    "Spanish",
    "Tamil",
    "Telugu",
  ];

  const femaleLanguages = [
    "Bengali",
    "English",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Malayalam",
    "Tamil",
    "Telugu",
  ];

  return (
    <div className="p-3 policybg">
      <Container
        className="py-5 text-center policybox"
        style={{ borderRadius: "30px" }}
      >
        <Row
          className="justify-content-center"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Col md={10} className="px-2">
            <h4 className="text-center mb-5">Text to Speech </h4>
            <p className="mb-4">
              <p>Supported Languages:</p>
            </p>
            <p className="text-center mb-4">
              <strong>Male&nbsp;&nbsp;: &nbsp;&nbsp;</strong>{" "}
              {maleLanguages.map((language, index) => (
                <span key={index} style={{ fontWeight: "lighter" }}>
                  {language}{" "}
                  <span
                    role="img"
                    aria-label="check"
                    style={{
                      color: "yellowgreen",
                      margin: "10px",
                      borderRadius: "50%",
                      fontWeight: "lighter",
                    }}
                  >
                    &#10004;
                  </span>
                </span>
              ))}
            </p>
            <p className="text-center mb-4">
              <strong>Female &nbsp;&nbsp;: &nbsp;&nbsp;</strong>{" "}
              {femaleLanguages.map((language, index) => (
                <span key={index} style={{ fontWeight: "lighter" }}>
                  {language}
                  <span
                    role="img"
                    aria-label="check"
                    style={{
                      color: "yellowgreen",
                      margin: "10px",
                      borderRadius: "50%",
                      fontWeight: "lighter",
                    }}
                  >
                    &#10004;
                  </span>
                </span>
              ))}
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="p-5">
        <Col md={{ span: 8, offset: 4 }}></Col>
      </Container>
    </div>
  );
};

export default OurServices;
