import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./banner.css";

const greetings = [
  "Hello", "Hola", "Bonjour", "Hallo", "Ciao", "Ola",
  "Marhaba", "Namaste",  "Vanakkam",  "Xin chao",  "Pryvit", "Salut",
  "Ni hao", "Konnichiwa",  "Salam", 
];

const Banner = () => {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    let timer = setTimeout(() => {
      handleType();
    }, typingSpeed);

    return () => clearTimeout(timer);
  }, [text, isDeleting, loopNum, typingSpeed]);

  const handleType = () => {
    const i = loopNum % greetings.length;
    const fullText = greetings[i];

    setText(
      isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1)
    );

    setTypingSpeed(isDeleting ? 30 : 150);

    if (!isDeleting && text === fullText) {
      setTimeout(() => setIsDeleting(true), 500);
    } else if (isDeleting && text === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
    }
  };

  return (
    <div className="banner">
      <Container>
        <div className="greeting-container">
          <h1 className="banner-greeting">
            <span className="typewriter">{text}</span>
          </h1>
        </div>
      </Container>
    </div>
  );
};

export default Banner;
