import React from 'react';
import { Link } from 'react-router-dom';
import '../Industries/Industries.css';
import { FaCalendarAlt, FaStethoscope, FaPills, FaFileInvoiceDollar } from 'react-icons/fa';

const Healthcare = () => {
  return (
    <div className="industry-container">
      <section className="industry-header">
        <h1>AI-Powered Solutions for Healthcare</h1>
        <p>Enhance patient care and optimize healthcare operations with cutting-edge AI technology.</p>
      </section>
      
      <section className="features">
        <h2>Key Benefits for Healthcare</h2>
        <ul>
          <li>
            <FaCalendarAlt className="icon" />
            <div>
              <h3>Efficient Appointment Management</h3>
              <p>Streamline scheduling processes, reduce wait times, and improve resource allocation.</p>
            </div>
          </li>
          <li>
            <FaStethoscope className="icon" />
            <div>
              <h3>Intelligent Symptom Triage</h3>
              <p>Provide preliminary assessments and guide patients to appropriate care levels.</p>
            </div>
          </li>
          <li>
            <FaPills className="icon" />
            <div>
              <h3>Medication Management Support</h3>
              <p>Send automated reminders and track adherence to improve patient outcomes.</p>
            </div>
          </li>
          <li>
            <FaFileInvoiceDollar className="icon" />
            <div>
              <h3>Streamlined Billing Inquiries</h3>
              <p>Handle routine insurance and billing questions, freeing up staff for complex issues.</p>
            </div>
          </li>
        </ul>
      </section>
      
      <section className="cta">
        <h2>Ready to Elevate Your Healthcare Services?</h2>
        <p>Discover how Verbalyze can transform patient care and operational efficiency. Contact us today for a customized demonstration.</p>
        <Link to="/meet" className="cta-button">Request a Demo</Link>
      </section>
    </div>
  );
};

export default Healthcare;
