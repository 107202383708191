import React from "react";
import "./supportpartner.css";
import { img1, img2, img3, img4, img5, img6 } from "../../assets";
import { Container } from "react-bootstrap";

const partnerLogos = [img1, img2, img3, img4, img5, img6];

const SupportPartner = () => {
  return (
    <div className="supportbg">
      <Container fluid>
        <h2 className="partners-heading">Our Support Partners</h2>
        <div className="logos">
          <div className="logos-slide-container">
            <div className="logos-slide">
              {partnerLogos.map((logo, index) => (
                <img
                  src={logo}
                  alt={`Logo ${index + 1}`}
                  key={index}
                  className="logo-img"
                />
              ))}
            </div>
            <div className="logos-slide">
              {partnerLogos.map((logo, index) => (
                <img
                  src={logo}
                  alt={`Logo ${index + 1}`}
                  key={index + partnerLogos.length}
                  className="logo-img"
                />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SupportPartner;
