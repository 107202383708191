import React from 'react';
import { Link } from 'react-router-dom';
import '../Industries/Industries.css';
import { FaUserClock, FaShieldAlt, FaChartLine, FaFileAlt } from 'react-icons/fa';

const BFSI = () => {
  return (
    <div className="industry-container">
      <section className="industry-header">
        <h1>AI-Powered Solutions for BFSI</h1>
        <p>Revolutionize customer experience and streamline operations in the Banking, Financial Services, and Insurance sector.</p>
      </section>
      
      <section className="features">
        <h2>Key Benefits for BFSI</h2>
        <ul>
          <li>
            <FaUserClock className="icon" />
            <div>
              <h3>24/7 Customer Support</h3>
              <p>Provide round-the-clock assistance for account inquiries, transaction support, and general banking information.</p>
            </div>
          </li>
          <li>
            <FaShieldAlt className="icon" />
            <div>
              <h3>Fraud Detection and Prevention</h3>
              <p>Utilize AI to identify suspicious activities and alert customers in real-time, enhancing security measures.</p>
            </div>
          </li>
          <li>
            <FaChartLine className="icon" />
            <div>
              <h3>Personalized Financial Advice</h3>
              <p>Offer tailored product recommendations and financial guidance based on individual customer profiles and transaction history.</p>
            </div>
          </li>
          <li>
            <FaFileAlt className="icon" />
            <div>
              <h3>Streamlined Loan Processing</h3>
              <p>Accelerate loan application processes by automating initial screenings and document verifications.</p>
            </div>
          </li>
        </ul>
      </section>
      
      <section className="cta">
        <h2>Ready to Transform Your BFSI Operations?</h2>
        <p>Discover how Verbalyze can revolutionize your customer service and operational efficiency. Contact us today for a personalized demo.</p>
        <Link to="/meet" className="cta-button">Request a Demo</Link>
      </section>
    </div>
  );
};

export default BFSI;
