import "./App.css";
import Login from "./components/auth/login";
import Signup from "./components/auth/Signup";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import About from "./pages/aboutus/about";
import Home from "./pages/Home/Home";
import PrivacyPolicy from "./pages/policy/privacyPolicy";
import DataSecurityPolicy from "./pages/policy/dataSecurityPolicy";
import TermsOfService from "./pages/policy/termsOfService";
import OurServices from "./pages/services/service";
import Pricing from "./components/Pricing/Pricing";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ContactUs from "./pages/ContactUs/ContactUs";
import PartnerWithUs from "./pages/PartnerWithUs/PartnerWithUs";
import Career from "./pages/Career/Career";
import Newsroom from "./pages/Newsroom/Newsroom";
import Blog from "./pages/Blog/Blog";
import BFSI from "./pages/Industries/BFSI";
import Healthcare from "./pages/Industries/Healthcare";
import Utilities from "./pages/Industries/Utilities";
import Retail from "./pages/Industries/Retail";
import OtherIndustries from "./pages/Industries/OtherIndustries";
import CookiePreferences from "./pages/CookiePreferences/CookiePreferences";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/data-security-policy" element={<DataSecurityPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/services" element={<OurServices />} />
          <Route path="/meet" element={<ContactUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/partner" element={<PartnerWithUs />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/newsroom" element={<Newsroom />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/industry/bfsi" element={<BFSI />} />
          <Route path="/industry/healthcare" element={<Healthcare />} />
          <Route path="/industry/utilities" element={<Utilities />} />
          <Route path="/industry/retail" element={<Retail />} />
          <Route path="/industry/other" element={<OtherIndustries />} />
          <Route path="/cookie-preferences" element={<CookiePreferences />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
