import React from 'react';
import { Link } from 'react-router-dom';
import '../Industries/Industries.css';
import { FaShoppingCart, FaChartLine, FaTruck, FaExchangeAlt } from 'react-icons/fa';

const Retail = () => {
  return (
    <div className="industry-container">
      <section className="industry-header">
        <h1>AI-Powered Solutions for Retail</h1>
        <p>Elevate customer experience and drive sales growth in the retail sector with innovative AI technology.</p>
      </section>
      
      <section className="features">
        <h2>Key Benefits for Retail</h2>
        <ul>
          <li>
            <FaShoppingCart className="icon" />
            <div>
              <h3>Intelligent Product Information</h3>
              <p>Provide instant answers to product queries and real-time inventory status updates.</p>
            </div>
          </li>
          <li>
            <FaChartLine className="icon" />
            <div>
              <h3>Personalized Recommendations</h3>
              <p>Offer tailored product suggestions based on customer preferences and purchase history.</p>
            </div>
          </li>
          <li>
            <FaTruck className="icon" />
            <div>
              <h3>Seamless Order Tracking</h3>
              <p>Enable customers to easily track orders and receive proactive shipping updates.</p>
            </div>
          </li>
          <li>
            <FaExchangeAlt className="icon" />
            <div>
              <h3>Efficient Returns Management</h3>
              <p>Streamline the process of initiating returns and exchanges to enhance customer satisfaction.</p>
            </div>
          </li>
        </ul>
      </section>
      
      <section className="cta">
        <h2>Ready to Transform Your Retail Experience?</h2>
        <p>Discover how Verbalyze can revolutionize your customer interactions and boost sales. Contact us today for a personalized demonstration.</p>
        <Link to="/meet" className="cta-button">Request a Demo</Link>
      </section>
    </div>
  );
};

export default Retail;
