import React from 'react';
import './PartnerWithUs.css';

const PartnerWithUs = () => {
  return (
    <div className="partner-with-us-container">
      <header className="partner-header">
        <h1>Partner With Us</h1>
        <p>Join forces with Verbalyze to revolutionize customer experiences</p>
      </header>

      <section className="partner-benefits">
        <h2>Why Partner With Us?</h2>
        <div className="benefits-grid">
          <div className="benefit-item">
            <h3>Innovative Technology</h3>
            <p>Access cutting-edge AI and voice technology solutions</p>
          </div>
          <div className="benefit-item">
            <h3>Expand Your Offerings</h3>
            <p>Enhance your product portfolio with our advanced solutions</p>
          </div>
          <div className="benefit-item">
            <h3>Collaborative Growth</h3>
            <p>Benefit from joint marketing and sales opportunities</p>
          </div>
          <div className="benefit-item">
            <h3>Expert Support</h3>
            <p>Receive dedicated technical and business support</p>
          </div>
        </div>
      </section>

      <section className="partnership-types">
        <h2>Partnership Opportunities</h2>
        <div className="partnership-grid">
          <div className="partnership-item">
            <h3>Technology Partners</h3>
            <p>Integrate our AI solutions into your existing products</p>
          </div>
          <div className="partnership-item">
            <h3>Reseller Partners</h3>
            <p>Offer our products to your customers and expand your business</p>
          </div>
          <div className="partnership-item">
            <h3>Strategic Alliances</h3>
            <p>Collaborate on joint solutions and market opportunities</p>
          </div>
        </div>
      </section>

      <section className="partner-cta">
        <h2>Ready to Partner?</h2>
        <p>Take the first step towards a mutually beneficial partnership</p>
        <p className="partner-email">
          <a href="mailto:verbalyzeteam@verbalyze.in">verbalyzeteam@verbalyze.in</a>
        </p>
      </section>
    </div>
  );
};

export default PartnerWithUs;
