import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { FaCog, FaBrain, FaRocket } from 'react-icons/fa';
import AiTalkerAnimation from './AiTalkerAnimation';
import './YourOwnAi.css';

const YourOwnAi = () => {
  const RightSideContent = () => (
    <div className="right-side-content shifted-content">
      <div className="your-own-ai-item">
        <FaCog className="your-own-ai-icon" />
        <div>
          <h3>Fully Customizable</h3>
          <p>Tailor your AI assistant to match your brand voice, industry knowledge, and specific business needs.</p>
        </div>
      </div>
      <div className="your-own-ai-item">
        <FaBrain className="your-own-ai-icon" />
        <div>
          <h3>Continuous Learning</h3>
          <p>Your AI evolves with your business, constantly improving its knowledge and capabilities.</p>
        </div>
      </div>
      <div className="your-own-ai-item">
        <FaRocket className="your-own-ai-icon" />
        <div>
          <h3>Boost Productivity</h3>
          <p>Automate routine tasks and queries, allowing your team to focus on high-value activities.</p>
        </div>
      </div>
    </div>
  );

  return (
    <section className="your-own-ai-section">
      <div className="your-own-ai-header">
        <h2 className="your-own-ai-heading">Your Own AI Assistant</h2>
        <p className="your-own-ai-subheading">
          Empower your business with a customized AI solution tailored to your unique needs and brand identity.
        </p>
      </div>
      <Container>
        <div className="your-own-ai-content">
          <Row className="align-items-center">
            <Col md={6} className="conversation-card-column">
              <Card className="conversation-card">
                <Card.Body>
                  <AiTalkerAnimation />
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="right-side-content-column">
              <RightSideContent />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default YourOwnAi;
