import React, { useState, useEffect } from 'react';
import { FaShieldAlt } from 'react-icons/fa';
import './GuardrailsAnimation.css';

const GuardrailsAnimation = () => {
  const [stage, setStage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setStage((prevStage) => (prevStage + 1) % 2);
    }, 5000); // Change stage every 5 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="guardrails-animation">
      {stage === 0 && (
        <div className="animation-frame introduction">
          <h3>Stays On-Topic with Guardrails</h3>
          <div className="shield-container">
            <FaShieldAlt className="shield-icon green" />
          </div>
        </div>
      )}
      {stage === 1 && (
        <div className="animation-frame off-topic">
          <h3>Avoid Off-Topic Question</h3>
          <div className="shield-container">
            <FaShieldAlt className="shield-icon red" />
          </div>
        </div>
      )}
    </div>
  );
};

export default GuardrailsAnimation;
