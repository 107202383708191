import React from 'react';
import './policyPages.css';

const DataSecurityPolicy = () => {
  return (
    <div className="policy-container">
      <h1 className="policy-title">Verbalyze Data Security Policy</h1>
      <p className="policy-date">Last updated: February 12, 2024</p>

      <div className="policy-content">
        <h2>Our Commitment to Data Security</h2>
        <p>At Verbalyze, we are committed to protecting the security of your data. We have implemented robust security measures to safeguard your information against unauthorized access, alteration, disclosure, or destruction.</p>

        <h2>What Information We Collect</h2>
        <h3>Personal Data</h3>
        <p>"Personal Data" is information relating to a specific identified or identifiable person. Generally, Verbalyze collects two types of Personal Data: Personal Data from Website users and Customers, and Personal Data from Customer's End Users.</p>

        <h4>Website Data</h4>
        <p>Verbalyze collects several types of information from and about users of our website, including information by which they may be personally identified, such as name, company name, e-mail address, telephone number, or any other identifier by which you may be contacted online or offline.</p>

        <h4>Customer Data</h4>
        <p>Verbalyze collects Personal Data from Customers relating to Customer accounts and management of them. As Customers navigate the Verbalyze services, we may also collect information through the use of commonly-used information-gathering tools, such as cookies and Web beacons ("Web Site Navigational Information").</p>

        <h4>Customer's End User Data</h4>
        <p>As part of the provision of the Verbalyze service to Customers, Verbalyze collects certain Personal Data and other, Non-Personal Data about Customer's End Users as determined by the applicable Customer ("Customer's End User Data").</p>

        <h3>Non-Personal Data</h3>
        <p>"Non-Personal Data" is information that does not identify a specific person. We collect Non-Personal Data from website users, Customers, and Customer's End Users.</p>

        <h2>What Information We Do Not Collect</h2>
        <p>We do not knowingly collect or solicit personal information from anyone under the age of 18. If you are under 18, please do not attempt to register for the services provided by Verbalyze or send any personal information about yourself to us. If we learn that we have collected personal information from a child under the age of 18 years, we will delete that information as quickly as possible. If you believe that a child under 18 may have provided us personal information, please contact us at <a href="mailto:verbalyzeteam@verbalyze.in">verbalyzeteam@verbalyze.in</a>.</p>

        <h2>Sharing of Data</h2>
        <p>We do not sell or share Personal Data or Non-Personal Data related to our Customers' End Users. We use all such data to strictly provide Services or Websites and its content to you. We will not share Personal Data with other parties except as provided in specific circumstances outlined in our full Privacy Policy.</p>

        <h2>Security Measures</h2>
        <p>We have implemented the following security measures:</p>
        <ul>
          <li>Physical Security: Our data centers are protected by industry-standard physical security measures.</li>
          <li>Access Control: We employ strict access controls to ensure that only authorized personnel can access sensitive data.</li>
          <li>Encryption: We use strong encryption protocols to protect data in transit and at rest.</li>
          <li>Regular Security Audits: We conduct regular security audits and vulnerability assessments to identify and address potential security risks.</li>
          <li>Employee Training: Our employees undergo regular security awareness training to ensure they understand and follow best practices in data security.</li>
        </ul>

        <h2>Your Rights</h2>
        <p>Depending on your location and subject to applicable law, you may have certain rights with regard to your Personal Data, such as the right to:</p>
        <ul>
          <li>Access your Personal Data</li>
          <li>Correct inaccurate Personal Data</li>
          <li>Delete your Personal Data</li>
          <li>Restrict the use of your Personal Data</li>
          <li>Object to the processing of your Personal Data</li>
          <li>Receive your Personal Data in a usable electronic format and transmit it to a third party (right to data portability)</li>
          <li>Withdraw your consent to the processing of your Personal Data</li>
        </ul>
        <p>If you wish to exercise any of these rights, please contact us using the information provided in the Contact Us section.</p>

        <h2>Incident Response</h2>
        <p>In the event of a data breach, we have a comprehensive incident response plan in place to quickly identify, contain, and mitigate any potential impact.</p>

        <h2>Compliance</h2>
        <p>We are committed to complying with all applicable data protection laws and regulations. Our security practices are designed to meet or exceed industry standards.</p>

        <h2>Continuous Improvement</h2>
        <p>We continuously evaluate and upgrade our security measures to ensure the ongoing protection of your data. This includes staying informed about the latest security threats and best practices in data protection.</p>

        <h2>Your Role in Data Security</h2>
        <p>While we take extensive measures to protect your data, we also encourage you to take steps to protect your own information:</p>
        <ul>
          <li>Use strong, unique passwords for your Verbalyze account.</li>
          <li>Do not share your account credentials with others.</li>
          <li>Be cautious when accessing your account from public or shared computers.</li>
          <li>Keep your devices and software up to date with the latest security patches.</li>
        </ul>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about our data security practices, please contact our Information Security team at:</p>
        <p>Email: <a href="mailto:verbalyzeteam@verbalyze.in">verbalyzeteam@verbalyze.in</a></p>
        <p>Address: Aiverbalyze Technologies Pvt. Ltd., Thane, Maharashtra, India</p>

        <p>We are committed to addressing any questions or concerns you may have about the security of your data promptly and thoroughly.</p>
      </div>
    </div>
  );
};

export default DataSecurityPolicy;
