import React from 'react';
import './policyPages.css';

const PrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <h1 className="policy-title">Verbalyze Privacy Policy</h1>
      <p className="policy-date">Last updated: February 12, 2024</p>

      <div className="policy-content">
        <p>This Privacy Policy ("Policy") applies to all the services offered by Aiverbalyze Technologies Pvt. Ltd., also known as Verbalyze, and its affiliates (collectively "Verbalyze"). This Policy also applies to information we collect when you apply for a job at Verbalyze. This Policy covers the information about our usage of any data provided by you to Verbalyze on our website or any other Verbalyze platform. We are committed to protecting your privacy. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this Policy. Verbalyze reserves the right to update this Policy frequently, hence you should check this page from time to time.</p>

        <h2>Definitions</h2>
        <p>"Customer" is hereby referred to as an entity that directly engages with Verbalyze to use our services. "Customer's End Users" are hereby referred to as end-users who use customer platforms and thus use Verbalyze services indirectly.</p>

        <h2>Our Commitment</h2>
        <p>We are committed to respecting and protecting the privacy of both Customer and Customer's End Users and the personal information we may obtain through our services or other Company operations. Accordingly, we have created this Privacy Policy to inform all users about how we collect, use and share Customer information and personal information of Customer's End Users, and about our customer information privacy practices in general.</p>

        <h2>Contact Us</h2>
        <p>We are always ready to address questions and concerns regarding this privacy policy and our privacy practices. If you would like to contact customer service, please contact us via <a href="mailto:verbalyzeteam@verbalyze.in">verbalyzeteam@verbalyze.in</a></p>

        <h2>Changes to This Policy</h2>
        <p>We may modify this Privacy Policy from time to time to reflect changes in our privacy practices. All users, including Customers and Customer's End Users, are encouraged to review this Privacy Policy periodically and to check the "Last Updated" date at the top of the Privacy Policy for the most recent version. If we make changes to this Privacy Policy, we will notify you here, by email, or through other industry-standard means.</p>

        <h2>Contact Information</h2>
        <p>For any questions or concerns about this Privacy Policy or our data practices, please contact our Data Protection Officer at:</p>
        <p>Email: <a href="mailto:verbalyzeteam@verbalyze.in">verbalyzeteam@verbalyze.in</a></p>
        <p>Address: Aiverbalyze Technologies Pvt. Ltd., Thane, Maharashtra, India</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
