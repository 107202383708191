import React from 'react';
import './Career.css';

const Career = () => {
  return (
    <div className="career-container">
      <header className="career-header">
        <h1>Join Our Team</h1>
        <p>Shape the future of AI-powered customer experiences</p>
      </header>

      <section className="company-culture">
        <h2>Our Culture</h2>
        <div className="culture-grid">
          <div className="culture-item">
            <h3>Innovation</h3>
            <p>We push the boundaries of what's possible in AI and voice technology</p>
          </div>
          <div className="culture-item">
            <h3>Collaboration</h3>
            <p>We believe in the power of teamwork and diverse perspectives</p>
          </div>
          <div className="culture-item">
            <h3>Growth</h3>
            <p>We invest in our employees' personal and professional development</p>
          </div>
          <div className="culture-item">
            <h3>Impact</h3>
            <p>We're committed to making a positive difference in the world</p>
          </div>
        </div>
      </section>

      <section className="benefits">
        <h2>Why Work With Us</h2>
        <ul className="benefits-list">
          <li>Competitive salary and equity packages</li>
          <li>Flexible work arrangements</li>
          <li>Comprehensive health and wellness benefits</li>
          <li>Continuous learning and development opportunities</li>
          <li>Collaborative and inclusive work environment</li>
        </ul>
      </section>

      <section className="career-cta">
        <h2>Interested in joining our team?</h2>
        <p>We're always looking for talented individuals to join our team</p>
        <p className="email-contact">Send your resume to: <a href="mailto:verbalyzeteam@verbalyze.in">verbalyzeteam@verbalyze.in</a></p>
      </section>
    </div>
  );
};

export default Career;
